import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Guarantee from '../components/Guarantee.vue'
import Kittens from '../components/Kittens.vue'
import Kittensp from '../components/Kittens-p.vue'
import Adults from '../components/Adults.vue'
import PreSale from '../components/PreSale.vue'
import PreSale2202 from '../components/PreSale2202.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/kittens/24070701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24070701.vue')
  },
  {
    path: '/kittens/24070702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24070702.vue')
  },
  {
    path: '/kittens/24070703',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24070703.vue')
  },
  {
    path: '/kittens/24062301',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24062301.vue')
  },
  {
    path: '/kittens/24062302',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24062302.vue')
  },
  {
    path: '/kittens/24062303',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24062303.vue')
  },
  {
    path: '/kittens/24062304',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24062304.vue')
  },
  {
    path: '/kittens/24062305',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24062305.vue')
  },
  {
    path: '/kittens/24060801',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24060801.vue')
  },
  {
    path: '/kittens/24052301',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24052301.vue')
  },
  {
    path: '/kittens/24052302',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24052302.vue')
  },
  {
    path: '/kittens/24052303',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24052303.vue')
  },
  {
    path: '/kittens/24051101',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051101.vue')
  },
  {
    path: '/kittens/24051102',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051102.vue')
  },
  {
    path: '/kittens/24051103',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051103.vue')
  },
  {
    path: '/kittens/24051104',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051104.vue')
  },
  {
    path: '/kittens/24051105',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051105.vue')
  },
  {
    path: '/kittens/24051001',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051001.vue')
  },
  {
    path: '/kittens/24051002',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051002.vue')
  },
  {
    path: '/kittens/24051003',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24051003.vue')
  },
  {
    path: '/kittens/24041901',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24041901.vue')
  },
  {
    path: '/kittens/24041902',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24041902.vue')
  },
  {
    path: '/kittens/24041903',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24041903.vue')
  },
  {
    path: '/kittens/24022701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24022701.vue')
  },
  {
    path: '/kittens/24022702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24022702.vue')
  },
  {
    path: '/kittens/24022703',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24022703.vue')
  },
  {
    path: '/kittens/24022704',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24022704.vue')
  },
  {
    path: '/kittens/24022705',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24022705.vue')
  },
  {
    path: '/kittens/24022706',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24022706.vue')
  },
  {
    path: '/kittens/24011203',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24011203.vue')
  },
  {
    path: '/kittens/24011202',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24011202.vue')
  },
  {
    path: '/kittens/24011201',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24011201.vue')
  },
  {
    path: '/kittens/24010703',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24010703.vue')
  },
  {
    path: '/kittens/24010702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24010702.vue')
  },
  {
    path: '/kittens/24010701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2024/24010701.vue')
  },
  {
    path: '/kittens/23122103',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23122103.vue')
  },
  {
    path: '/kittens/23122102',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23122102.vue')
  },
  {
    path: '/kittens/23122101',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23122101.vue')
  },
  {
    path: '/kittens/23121301',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23121301.vue')
  },
  {
    path: '/kittens/23100601',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23100601.vue')
  },
  {
    path: '/kittens/23100602',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23100602.vue')
  },
  {
    path: '/kittens/23100603',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23100603.vue')
  },
  {
    path: '/kittens/23100604',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23100604.vue')
  },
  {
    path: '/kittens/23100605',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23100605.vue')
  },
  {
    path: '/kittens/23093001',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23093001.vue')
  },
  {
    path: '/kittens/23090701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23090701.vue')
  },
  {
    path: '/kittens/23090702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23090702.vue')
  },
  {
    path: '/kittens/23072504',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23072504.vue')
  },
  {
    path: '/kittens/23072503',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23072503.vue')
  },
  {
    path: '/kittens/23072502',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23072502.vue')
  },
  {
    path: '/kittens/23072501',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23072501.vue')
  },
  {
    path: '/kittens/23051401',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23051401.vue')
  },
  {
    path: '/kittens/23042401',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23042401.vue')
  },
  {
    path: '/kittens/23042402',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23042402.vue')
  },
  {
    path: '/kittens/23042403',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23042403.vue')
  },
  {
    path: '/kittens/23042404',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23042404.vue')
  },
  {
    path: '/kittens/23042405',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23042405.vue')
  },
  {
    path: '/kittens/23041001',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23041001.vue')
  },
  {
    path: '/kittens/23041002',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23041002.vue')
  },
  {
    path: '/kittens/23040601',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23040601.vue')
  },
  {
    path: '/kittens/23040602',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23040602.vue')
  },
  {
    path: '/kittens/23040603',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23040603.vue')
  },
  {
    path: '/kittens/23040604',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23040604.vue')
  },
  {
    path: '/kittens/23032304',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23032304.vue')
  },
  {
    path: '/kittens/23032303',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23032303.vue')
  },
  {
    path: '/kittens/23032302',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23032302.vue')
  },
  {
    path: '/kittens/23032301',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23032301.vue')
  },
  {
    path: '/kittens/23032103',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23032103.vue')
  },
  {
    path: '/kittens/23032102',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23032102.vue')
  },
  {
    path: '/kittens/23032101',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23032101.vue')
  },
  {
    path: '/kittens/23031502',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23031502.vue')
  },
  {
    path: '/kittens/23031501',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2023/23031501.vue')
  },
  {
    path: '/kittens/22102904',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22102904.vue')
  },
  {
    path: '/kittens/22102903',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22102903.vue')
  },
  {
    path: '/kittens/22102902',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22102902.vue')
  },
  {
    path: '/kittens/22102901',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22102901.vue')
  },{
    path: '/kittens/22101601',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22101601.vue')
  },
  {
    path: '/kittens/22101503',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22101503.vue')
  },
  {
    path: '/kittens/22101502',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22101502.vue')
  },
  {
    path: '/kittens/22101501',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22101501.vue')
  },
  {
    path: '/kittens/22100705',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100705.vue')
  },
  {
    path: '/kittens/22100704',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100704.vue')
  },
  {
    path: '/kittens/22100703',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100703.vue')
  },
  {
    path: '/kittens/22100702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100702.vue')
  },
  {
    path: '/kittens/22100701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100701.vue')
  },
  {
    path: '/kittens/22100501',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100501.vue')
  },
  {
    path: '/kittens/22100502',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100502.vue')
  },
  {
    path: '/kittens/22100503',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100503.vue')
  },
  {
    path: '/kittens/22100504',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100504.vue')
  },
  {
    path: '/kittens/22100505',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100505.vue')
  },
  {
    path: '/kittens/22100601',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100601.vue')
  },
  {
    path: '/kittens/22100602',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22100602.vue')
  },
  {
    path: '/kittens/22092003',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22092003.vue')
  },
  {
    path: '/kittens/22092002',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22092002.vue')
  },
  {
    path: '/kittens/22092001',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22092001.vue')
  },
  {
    path: '/kittens/22082201',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22082201.vue')
  },
  {
    path: '/kittens/22081101',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22081101.vue')
  },
  {
    path: '/kittens/22081102',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22081102.vue')
  },
  {
    path: '/kittens/22081103',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22081103.vue')
  },
  {
    path: '/kittens/22081104',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22081104.vue')
  },
  {
    path: '/kittens/22081105',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22081105.vue')
  },
  {
    path: '/kittens/22060302',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22060302.vue')
  },
  {
    path: '/kittens/22060301',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22060301.vue')
  },
  {
    path: '/kittens/22060103',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22060103.vue')
  },
  {
    path: '/kittens/22060102',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22060102.vue')
  },
  {
    path: '/kittens/22060101',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22060101.vue')
  },
  {
    path: '/kittens/22053002',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22053002.vue')
  },
  {
    path: '/kittens/22053001',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22053001.vue')
  },
  {
    path: '/kittens/22052804',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22052804.vue')
  },
  {
    path: '/kittens/22052803',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22052803.vue')
  },
  {
    path: '/kittens/22052802',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22052802.vue')
  },
  {
    path: '/kittens/22052801',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22052801.vue')
  },
  {
    path: '/kittens/22052801',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22052801.vue')
  },
  {
    path: '/kittens/22052801',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22052801.vue')
  },
  {
    path: '/kittens/22041101',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22041101.vue')
  },
  {
    path: '/kittens/22040504',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040504.vue')
  },
  {
    path: '/kittens/22040503',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040503.vue')
  },
  {
    path: '/kittens/22040502',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040502.vue')
  },
  {
    path: '/kittens/22040501',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040501.vue')
  },
  {
    path: '/kittens/22040306',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040306.vue')
  },
  {
    path: '/kittens/22040305',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040305.vue')
  },
  {
    path: '/kittens/22040304',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040304.vue')
  },
  {
    path: '/kittens/22040303',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040303.vue')
  },
  {
    path: '/kittens/22040302',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040302.vue')
  },
  {
    path: '/kittens/22040301',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22040301.vue')
  },
  {
    path: '/kittens/22012203',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22012203.vue')
  },
  {
    path: '/kittens/22012202',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22012202.vue')
  },
  {
    path: '/kittens/22012201',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22012201.vue')
  },
  {
    path: '/kittens/22011703',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22011703.vue')
  },
  {
    path: '/kittens/22011702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22011702.vue')
  },
  {
    path: '/kittens/22011701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22011701.vue')
  },
  {
    path: '/kittens/22010603',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22010603.vue')
  },
  {
    path: '/kittens/22010602',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22010602.vue')
  },
  {
    path: '/kittens/22010601',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2022/22010601.vue')
  },
  {
    path: '/kittens/21122802',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21122802.vue')
  },
  {
    path: '/kittens/21122801',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21122801.vue')
  },
  {
    path: '/kittens/21122703',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21122703.vue')
  },
  {
    path: '/kittens/21122702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21122702.vue')
  },
  {
    path: '/kittens/21122701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21122701.vue')
  },
  {
    path: '/kittens/21112401',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21112401.vue')
  },
  {
    path: '/kittens/21100604',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21100604.vue')
  },
  {
    path: '/kittens/21100603',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21100603.vue')
  },
  {
    path: '/kittens/21100602',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21100602.vue')
  },
  {
    path: '/kittens/21100601',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21100601.vue')
  },
  {
    path: '/kittens/21082101',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21082101.vue')
  },
  {
    path: '/kittens/21082102',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21082102.vue')
  },
  {
    path: '/kittens/21082103',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21082103.vue')
  },
  {
    path: '/kittens/21082104',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21082104.vue')
  },
  {
    path: '/kittens/21081301',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21081301.vue')
  },
  {
    path: '/kittens/21072401',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21072401.vue')
  },
  {
    path: '/kittens/21072402',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21072402.vue')
  },
  {
    path: '/kittens/21072403',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21072403.vue')
  },
  {
    path: '/kittens/21072404',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21072404.vue')
  },
  {
    path: '/kittens/21072405',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21072405.vue')
  },
  {
    path: '/kittens/21071505',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21071505.vue')
  },
  {
    path: '/kittens/21071504',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21071504.vue')
  },
  {
    path: '/kittens/21071503',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21071503.vue')
  },
  {
    path: '/kittens/21071502',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21071502.vue')
  },
  {
    path: '/kittens/21071501',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21071501.vue')
  },
  {
    path: '/kittens/21062901',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21062901.vue')
  },
  {
    path: '/kittens/21061703',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21061703.vue')
  },
  {
    path: '/kittens/21061702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21061702.vue')
  },
  {
    path: '/kittens/21061701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21061701.vue')
  },
  {
    path: '/kittens/21042702',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21042702.vue')
  },
  {
    path: '/kittens/21042701',
    component: () => import(/* webpackChunkName: "about" */ '../components/kittens/2021/21042701.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kittens',
    name: 'Kittens',
    component: Kittens
  },
  {
    path: '/kittens-p',
    name: 'Kittensp',
    component: Kittensp
  },
  {
    path: '/adults',
    name: 'Adults',
    component: Adults
  },
  {
    path: '/guarantee',
    name: 'Guarantee',
    component: Guarantee
  },
  {
    path: '/presale2202',
    name: 'PreSale2202',
    component: PreSale2202
  },
  {
    path: '/presale',
    name: 'PreSale',
    component: PreSale
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
